import { FC } from "react";
import { useTranslation } from "react-i18next";

import "./Loading.css";

const Loader: FC = () => {
  const { t: translation } = useTranslation();

  return (
    <div className="loading-main">
      <div className="loadingio-spinner-dual-ball-hchn3d2m5bs">
        <div className="ldio-fr7iv3pd6t">
          <div />
          <div />
          <div />
        </div>
      </div>
      <p className="loading-txt">{translation("loading")}</p>
    </div>
  );
};

export default Loader;
