import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import ROUTES from "../../constant/routes";
import { IHeaderBanner } from "../../interfaces/headerBanner";
import config from "../../config/config";

import "./Header.scss";

interface IHeaderBannerProps {
  subscriptionTrialPeriodData: IHeaderBanner;
}

const HeaderBanner = (props: IHeaderBannerProps): JSX.Element => {
  const { subscriptionTrialPeriodData } = props;
  const { t: translation } = useTranslation();

  return (
    <div
      className="header-top"
      style={{
        backgroundImage: `url( ${config.devAdminUrl}${subscriptionTrialPeriodData.gif_url})`,
      }}
    >
      <div className="container">
        <div className="header-top-inner">
          <p>
            {translation("subscribe_between")}{" "}
            <span>
              {subscriptionTrialPeriodData?.start_date
                ? moment(subscriptionTrialPeriodData?.start_date).format(
                    "DD-MM-YYYY"
                  )
                : 0}{" "}
              {translation("to")}{" "}
              {subscriptionTrialPeriodData?.end_date
                ? moment(subscriptionTrialPeriodData?.end_date).format(
                    "DD-MM-YYYY"
                  )
                : 0}{" "}
            </span>
            {translation("and")}{" "}
            <Link
              to={ROUTES.HOME_PAGE_SUBSCRIPTION.replace(":id", "subscription")}
            >
              {translation("get_free_subscription_for")}
              <span>
                {subscriptionTrialPeriodData?.months}{" "}
                {subscriptionTrialPeriodData?.months === 1 ? "Month" : "Months"}{" "}
              </span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
