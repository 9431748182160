/* eslint-disable class-methods-use-this */

import {
  IMAGE_FILE_SIZE_LIMIT,
  IMAGE_FILE_TYPES,
} from "../constant/commonConstants";
import { ValidateDocumentFileResponse } from "../interfaces/commentInterface";

class Helper {
  /**
   * Helper to make first letter as capital of a word
   * @param string
   * @returns string
   */
  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Validate a file object for document type and size
   * @param file File
   * @returns Validation response
   */
  validateImageFile = (file: File): ValidateDocumentFileResponse => {
    let response: ValidateDocumentFileResponse = {
      isValid: true,
    };
    const { name, size } = file;

    if (size <= IMAGE_FILE_SIZE_LIMIT) {
      const fileExtension = name.split(".").pop();

      if (!IMAGE_FILE_TYPES.includes(`.${fileExtension}`)) {
        response = {
          isValid: false,
          error: "upload_only_image_files",
        };
      }
    } else {
      response = {
        isValid: false,
        error: "file_size_limit_exceeded",
        sizeUnit: "MB",
      };
    }
    return response;
  };

  /**
   *  google jwt
   */
  parseJwt(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          // eslint-disable-next-line
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const data = JSON.parse(jsonPayload);
    return data;
  }
}
export default new Helper();
