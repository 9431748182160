import { AppSize } from "../interfaces/commonInterface";

export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;
export const NAME_REGEX = /^[ a-zA-Z0-9\-’]+$/;
export const EMPTY_SPACE_REGEX = /^\s+$/;
export const IMAGE_FILE_SIZE_LIMIT = 5249494; //  KB
export const IMAGE_FILE_TYPES = [
  ".jpg",
  ".jpeg",
  ".png",
  ".jpg",
  ".JPEG",
  ".PNG",
];

export const CREATE_TICKET_ISSUES = [
  { value: "App not working", label: "App not working" },
  { value: "Not able to login", label: "Not able to login" },
  { value: "Issues with subscription", label: "Issues with subscription" },
];

export const CREATE_TICKET_CATEGORY = [
  { value: "General", label: "General" },
  { value: "Billing", label: "Billing" },
  { value: "Account", label: "Account" },
];

export const ACCOUNT_TABS = {
  ALL_APPS: "all-apps",
  QUESTIONNAIRE: "questionnaire",
  MY_SUBSCRIPTION: "my-subscription",
  PAYMENT_HISTORY: "payment-history",
};

export const SUBSCRIPTION_PLAN = {
  SERIOUS: "Serious",
  DABBLING: "Dabbling",
  FANATIC: "Fanatic",
};
export const TECH_CATEGORY = {
  BILLING: "Billing",
  ACCOUNT: "Account",
  GENERAL: "General",
};
export const REFERENCE_TYPE = {
  VIDEO: "VIDEO",
  ARTICLE: "ARTICLE",
};
export const COUPON_TYPE = {
  PERCENTAGE: "percentage",
  FIXED: "fixed",
};
export const COUPON_STATUS = {
  ACTIVE: "active",
  CANCELED: "canceled",
  TRIALING :"trialing"
};

export const APPSIZE :AppSize ={
  'Serious':5,
  'Dabbling':1,
}

