import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { IAuthReducerState } from "../../redux/reducers/AuthReducer";
import { RootState } from "../../redux/reducers/Index";

import ActionType from "../../resources/enums";
import ConfirmationModal from "../commonPopup/ConfirmationModal";
import ROUTES from "../../constant/routes";
import storage from "../../utils/storage";

import helper from "../../utils/helper";
import HeaderBanner from "./HeaderBanner";
import { IHeaderBanner } from "../../interfaces/headerBanner";
import getHeaderBannerOffer from "../../services/headerBanner";
import { userSubscribedPlan } from "../../services/stripe";
import { deleteAccount } from "../../services/auth";
import { toastMessageSuccess } from "../commonFields/CommonToastMessage";
import { RedirectForVerify } from "../../interfaces/userAuthInterface";
import { deleteAuth } from "../../redux/actions/AuthAction";
import getTermsOfUse from "../../services/termsOfUse";
import { ITermsOfUse } from "../../interfaces/userInterface";

import logo from "../../images/logo.png";
import userPlaceholder from "../../images/user-placeholder.jpg";

import "./Header.scss";

const Header: React.FC = () => {
  const dispatch :any= useDispatch();
  const navigate = useNavigate();
  const { t: translation } = useTranslation();

  const authReducer: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const [subscriptionTrialPeriod, setSubscriptionTrialPeriod] =
    useState<IHeaderBanner>();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const[showDeleteConfirmModal,setShowDeleteConfirmModal]=useState<boolean>(false);
  const [showToggle, setShowToggle] = useState(false);
  const [alertMessage, setDeleteAlertMessage] = useState<ITermsOfUse[]>([]);
  const [collapse, setCollapse] = useState<boolean>(true);

 
  useEffect(() => {
    !authReducer.isLoggedIn ? planOffer() : "";
    getDeleteAccountAlert();
  }, []);


  
  /**
   * @method to handle logout
   *
   */
  const handleLogOut = async () => {
    const rememberme: string | null = localStorage.getItem("rememberme") ;
    await localStorage.clear();
    storage.removeAll();    
    localStorage.setItem("rememberme", rememberme as string );
    await dispatch({
      type: ActionType.LOGOUT,
    });
    setShowConfirmModal(false);
    navigate(ROUTES.HOME);
  };

  const getDeleteAccountAlert = async () => {    
    const Res = await getTermsOfUse({ id: 4 });
    setDeleteAlertMessage(Res?.data?.data[0]?.content);
  };

  /**
   * get subscription trial offer banner
   *
   */
  const planOffer = async () => {
    const userSubscriptionData = await getHeaderBannerOffer();
    setSubscriptionTrialPeriod(userSubscriptionData?.data?.data);
  };

/**
   * @method to delete user account permanently
   *
   */
  const handleDeleteMyAccount = async () => {
    
    const userSubscribtionData = await userSubscribedPlan();
    if(userSubscribtionData?.data?.data.length > 0 && userSubscribtionData?.data?.data[0].status !=="canceled")
    {
      navigate(ROUTES.ACCOUNT_APPS, {
        state: {deleteMyAccount:true},
      })
    }
    else{
      const data={
        email:authReducer.authData.email
      }
      const deleteMyAccount = await deleteAccount(data);
      
      if (deleteMyAccount?.data?.status === 200) {
        dispatch(deleteAuth(true));
        toastMessageSuccess(translation("otp_sent"));    
        setTimeout(() => {
          navigate(ROUTES.VERIFICATION, {
            state: {
              email: deleteMyAccount?.data?.data?.email,
              bOtp: deleteMyAccount?.data?.bOtp,
              redirectedFrom: RedirectForVerify.DELETE,
            },
          });
        }, 1000);
      }
    }
    setShowDeleteConfirmModal(false);
  };
  
  return (
    <>
      <div className="header">
        {!authReducer.isLoggedIn && !isEmpty(subscriptionTrialPeriod) ? (
          <HeaderBanner subscriptionTrialPeriodData={subscriptionTrialPeriod} />
        ) : (
          ""
        )}
        <div className="header-inner">
        <div className="container">
          <nav className="navbar navbar-expand-md">
            <div className="w-100">
              <div className="row align-items-center">
                <div className="col-6 col-md-3 col-lg-3">
                  <div className="brand-logo">
                    <Link to="/">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                </div>

                <div className="col-6 order-md-2 col-md-2 col-lg-2">
                  <div className="header-action">
                    {!authReducer.isLoggedIn ? (
                      <button
                        type="button"
                        className="theme-button primary-btn"
                        onClick={() => navigate(ROUTES.LOGIN)}
                      >
                        <i className="far fa-user me-2" />
                        {translation("login")}
                      </button>
                    ) : (
                      <div className="user-drop">
                        <div className="dropdown user-drop">
                          <button
                            type="button"
                            className="user-drop-btn dropdown-toggle"
                            data-bs-toggle="dropdown"
                            onClick = {() => setShowToggle(!showToggle)}
                          >
                            <div className="circle-img">
                              <img
                                src={
                                  authReducer && authReducer?.authData?.image
                                    ? authReducer.authData.image
                                    : userPlaceholder
                                }
                                alt=""
                              />
                            </div>
                            <span>{helper.capitalizeFirstLetter(
                              authReducer?.authData?.name
                            )}
                            </span>
                            <i className= {showToggle ? "fas fa-chevron-up"  : "fas fa-chevron-down"} />
                          </button>
                          <ul className="dropdown-menu">
                            <li className="dropdown-item">
                              <Link
                                to={ROUTES.ACCOUNT_APPS}
                                className="dropdown-item"
                              >
                                {translation("my_account")}
                              </Link>
                            </li>
                            <li className="dropdown-item">
                              <Link to={ROUTES.FAQ} className="dropdown-item">
                                Faq
                              </Link>
                            </li>
                            <li className="dropdown-item">
                              <Link
                                to={ROUTES.TECH_SUPPORT}
                                className="dropdown-item"
                              >
                                {translation("tech_support")}
                              </Link>
                            </li>
                            <li className="dropdown-item">
                              <Link
                                to={ROUTES.TERMS_OF_SERVICE}
                                className="dropdown-item"
                              >
                                {translation("privacy_policy")}
                              </Link>
                            </li>
                            <li
                              className="dropdown-item"
                              onClick={() =>
                                setShowDeleteConfirmModal(!showConfirmModal)
                              }
                            >
                              <Link to="/" className="dropdown-item">
                                {" "}
                                {translation("delete_my_account")}
                              </Link>
                            </li>
                            <li
                              className="dropdown-item"
                              onClick={() =>
                                setShowConfirmModal(!showConfirmModal)
                              }
                            >
                              <Link to="/" className="dropdown-item">
                                {" "}
                                {translation("logout")}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 order-md-1 col-md-7 col-lg-7">
                  <div className="header-navbar-nav">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={() => setCollapse(!collapse)}
                      // data-bs-toggle="collapse"
                      // data-bs-target="#collapsibleNavbar"
                    >
                      <i className="fas fa-bars" />
                    </button>
                    <div
                      className={`collapse navbar-collapse ${collapse ? `` : `show`}`}
                      id="collapsibleNavbar"
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item" onClick={() => setCollapse(true)}>
                          <Link
                            to="/"
                            className={
                              window.location.pathname === "/"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            {translation("home")}
                          </Link>
                        </li>
                        <li className="nav-item" onClick={() => setCollapse(true)}>
                          <Link
                            to={ROUTES.APPS}
                            className={
                              window.location.pathname === ROUTES.APPS
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            {translation("apps")}
                          </Link>
                        </li>
                        <li className="nav-item" onClick={() => setCollapse(true)}>
                          <Link
                            to={ROUTES.MASTER_SERIES}
                            className={
                              window.location.pathname === ROUTES.MASTER_SERIES
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            {translation("bundles")}
                          </Link>
                        </li>
                        <li className="nav-item" onClick={() => setCollapse(true)}>
                          <Link
                            to={ROUTES.RESOURCE_HELP}
                            className={
                              window.location.pathname === ROUTES.RESOURCE_HELP
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            {translation("resources")}
                          </Link>
                        </li>
                        <li className="nav-item" onClick={() => setCollapse(true)}>
                          <Link
                            to={ROUTES.ABOUT}
                            className={
                              window.location.pathname === ROUTES.ABOUT
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            {translation("about_us")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        </div>
      </div>
      {showConfirmModal && (
        <ConfirmationModal
          heading="logout"
          description="logout_modal_description"
          onClickOkay={() => handleLogOut()}
          onClickCancel={() => setShowConfirmModal(false)}
        />
      )}
       {showDeleteConfirmModal && (
        <ConfirmationModal
          heading="Delete My Account"
          description={alertMessage as unknown as string}
          yesButtonText="Delete My Account"
          noButtonText="Cancel"
          isDangerousHtml
          onClickOkay={() => handleDeleteMyAccount()}
          onClickCancel={() => setShowDeleteConfirmModal(false)}
        />
      )}
    </>
  );
};
export default Header;
