export interface ILoginData {
  email: string;
  password: string;
}

export interface IFacebook {
  name: string;
  email: string;
  id: string;
  accessToken: string;
  userID: string;
  expiresIn: number;
  signedRequest: string;
  graphDomain: string;
  data_access_expiration_time: number;
}

export interface ISignUp {
  name: string;
  email: string;
  password: string;
  agreement: boolean;
  password_again: string;
  otp: number;
  bOtp: string;
  phone:string;
}

export interface IChangePassword {
  password: string;
  passwordAgain: string;
  oldPassword: string;
}
export interface IForgetPassword {
  email: string;
}

export interface IVerifyOtp {
  email: string;
  otp: number;
  bOtp: string;
}

export interface IResetPassword {
  email?: string;
  new_password: string;
  confirm_password: string;
}

export enum RedirectForVerify {
  FORGOT = "FORGOT",
  SIGNUP = "SIGNUP",
  DELETE="DELETE"
}
