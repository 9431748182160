import * as http from "../utils/http";
import endpoint from "../constant/endpoint";
import ApiResponse from "../resources/entity/IApiResponse";
import { IPayment } from "../interfaces/stripeInterface";

/**
 * @description:- SubscriptionPlanPayment api for payment of subscription plan
 * @returns
 */
export const getSubscriptionPlans = (): Promise<ApiResponse> => {
  return http.get(endpoint.stripe.GET_SUBSCRIPTION_PLANS);
};

/**
 * @description:- SubscriptionPlanPayment api for payment of subscription plan
 * @returns
 */
export const SubscriptionPlanPayment = (
  data: IPayment
): Promise<ApiResponse> => {
  return http.post(endpoint.stripe.SUBSCRIPTION_PLAN_PAYMENT, data);
};

/**
 * @description:- userSubscribedPlan api for getting subscribed plan of user.
 * @returns
 */
export const userSubscribedPlan = (): Promise<ApiResponse> => {
  return http.get(endpoint.stripe.USER_SUBSCRIPTION);
};

/**
 * @description:- userPaymentHistory api for getting payment history of user.
 * @returns
 */
export const userPaymentHistory = (): Promise<ApiResponse> => {
  return http.get(endpoint.stripe.USER_PAYMENT_HISTORY);
};
/**
 * @description:- cancelCurrentSubscriptionPlan api for cancelling user current subscription.
 * @returns
 */
export const cancelCurrentSubscriptionPlan = (): Promise<ApiResponse> => {
  return http.post(endpoint.stripe.CANCEL_USER_CURRENT_SUBSCRIPTION);
};
/**
 * @description:- userCoupon api for checking user coupon.
 * @returns
 */
export const userCoupon = (): Promise<ApiResponse> => {
  return http.get(endpoint.stripe.GET_COUPON);
};

/**
 * @description:- getCouponById api for  getting coupon by stripeCoupon id
 * @returns
 */
export const getCouponById = (
  stripe_coupon_id: string
): Promise<ApiResponse> => {
  return http.post(
    endpoint.stripe.GET_COUPON_BY_COUPON_ID.replace(
      `:stripe_coupon_id`,
      stripe_coupon_id
    )
  );
};
/**
 * @description:- validateCoupon api for  checking coupon entered byu user.
 * @returns
 */
export const validateCoupon = (body?: {
  stripe_coupon_id: string;
}): Promise<ApiResponse> => {
  return http.post(endpoint.stripe.VALIDATE_COUPON, body);
};

/**
 * @description:- userCoupon api for checking user coupon.
 * @returns
 */
export const subscriptionValidUser = (): Promise<ApiResponse> => {
  return http.get(endpoint.stripe.SUBSCRIPTION_OFFER);
};
