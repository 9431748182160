import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

interface Iprops {
  heading: string;
  description?: string;
  note?: string;
  yesButtonText?: string;
  noButtonText?: string;
  okayButtonText?: string;
  onClickOkay?: () => void;
  onClickCancel?: () => void;
  disabled?: boolean;
  onIconClick?: boolean;
  onClickIconCancel?: () => void;
  isDangerousHtml?: boolean;
  className?: string;
}

const ConfirmationModal: React.FC<Iprops> = (props: Iprops) => {
  const sanitizer = DOMPurify.sanitize;
  const { t: translation } = useTranslation();
  const {
    heading,
    description,
    isDangerousHtml,
    note,
    yesButtonText ="Yes",
    noButtonText = "No",
    onClickOkay,
    onClickCancel,
    okayButtonText,
    onClickIconCancel,
    onIconClick,
    disabled,
    className = ""
  } = props;

  return (
    <div
      className={`modal theme-modal modal-custom-show ${className}`}
      id="exampleModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" id="">
          <div className="modal-header header-content-center border-0">
            {/* <div className="modal-heading">
              <h3>fdfsdf</h3>
            </div> */}
            <div className="modal-closer">
              <button
                type="button"
                className="modal-closer-btn"
                data-bs-dismiss="modal"
                onClick={onIconClick ? onClickIconCancel : onClickCancel}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div className="modal-body text-center close-btn text-justify" id="modalbody">
            <h4 className="mb-3 space">
              <b>
                <span className="color-theme">{translation(heading)}</span>
              </b>
            </h4>
            <p className="mb-4" style={{ color: "red", fontSize: "12px" }}>
              {translation(note as string)}
            </p>
            {!isDangerousHtml ? (
              <p className="mb-4">{translation(description as string)} 
              </p>
            ) : null}
            {isDangerousHtml && description ? (
              <p
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: sanitizer(description),
                }} 
              >
              </p>
            ) : null}
          </div>
          <div className="action-btn">
            {okayButtonText ? (
              <button
                className="theme-button primary-btn w-100"
                type="button"
                onClick={onClickCancel}
                disabled={disabled}
              >
                {okayButtonText}
              </button>
            ) : (
              <div className="d-flex">
                <button
                  className="theme-button greybg-btn w-100"
                  type="button"
                  onClick={onClickCancel}
                >
                  {noButtonText}
                </button>
                <button
                  className="theme-button primary-btn w-100"
                  type="button"
                  onClick={onClickOkay}
                  disabled={disabled}
                >
                  {yesButtonText}{" "}
                  {disabled && (
                    <div className="spinner-border text-light" role="status" />
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
