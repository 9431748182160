import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, matchRoutes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isExpired } from "react-jwt";

import { IAuthReducerState } from "../redux/reducers/AuthReducer";
import { RootState } from "../redux/reducers/Index";

import Storage from "../utils/storage";
import { toastMessageError } from "../components/commonFields/CommonToastMessage";
import { COMMON_ROUTES, USER_ROUTES } from "../constant/routes";

interface Props {
  component: React.ComponentType;
  // path?: string;
}

const ProtectedRoutes: React.FC<Props> = ({ component: RouteComponent }) => {
  const { t: translation } = useTranslation();
  const authReducer: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const { pathname } = useLocation();

  const userRoute = matchRoutes(USER_ROUTES, pathname);
  const commonRoute = matchRoutes(COMMON_ROUTES, pathname);

  const tokenData = Storage.get("__RTA__");

  useEffect(() => {
    sessionExpire();
  }, []);

  const sessionExpire = () => {
    const isMyTokenExpired = isExpired(tokenData);

    if (isMyTokenExpired) {
      localStorage.clear();
      toastMessageError(translation("token-expired"));
      window.location.reload();
      // return <Navigate to="/" />;
    }
  };

  if (tokenData && authReducer.isLoggedIn) {
    if (userRoute?.length || commonRoute?.length) {
      return <RouteComponent />;
    }
    return <h1 className="text-center">Unauthorized user: 404!</h1>;
  }

  return <Navigate to="/login" />;
};
export default ProtectedRoutes;
