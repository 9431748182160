import axios, { AxiosResponse } from "axios";
import { getAccessToken } from "../helper/storage";

import config from "../config/config";
import { withData, withError } from "./api";

export const http = axios.create({
  baseURL: config.baseUrl,
  headers: {
    "content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT",
    Accept: "application/json, text/plain, /",
  },
});

http.interceptors.request.use((req: any) => {
  if (getAccessToken())
    req.headers.Authorization = `Bearer ${getAccessToken()}`;

  return req;
});

http.interceptors.response.use(
  (res) => withData(res.data) as AxiosResponse<any>,
  (err) => withError(err?.response?.data?.error)
);

export function get<P>(url: string, params?: P): Promise<any> {
  return http({
    method: "get",
    url,
    params,
  });
}

export function post<D, P>(url: string, data?: D, params?: P): any {
  return http({
    method: "post",
    url,
    data,
    params,
  });
}

export function postFile<D, P>(url: string, data: D, params?: P): any {
  return http({
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    url,
    data,
    params,
  });
}

export function put<D, P>(url: string, data: D, params?: P): any {
  return http({
    method: "put",
    url,
    data,
    params,
  });
}

export function patch<D, P>(url: string, data: D, params?: P): any {
  return http({
    method: "patch",
    url,
    data,
    params,
  });
}

export function remove<P>(url: string, params?: P): any {
  return http({
    method: "delete",
    url,
    params,
  });
}
