import IUserInterface from "../../interfaces/userInterface";
import ActionType from "../../resources/enums";
import Storage from "../../utils/storage";

export interface IAuthReducerState {
  isLoggedIn: boolean;
  authData: IUserInterface;
}

const authData = Storage.get("authData")
  ? JSON.parse(Storage.get("authData") as string)
  : {};

const initialState: IAuthReducerState = {
  isLoggedIn: false,
  authData,
};
export declare type ReduxAction = {
  type: string;
  payload?: any;
};

const AuthReducer = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case ActionType.LOGIN: {
      return {
        ...state,
        isLoggedIn: true,
        authData: action?.payload,
      };
    }
    case ActionType.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        authData: {},
      };
    case ActionType.UPDATE_AUTH:
      return {
        ...state,
        authData: {
          ...state.authData,
          name: action?.payload?.name,
          image: action?.payload?.image
            ? action?.payload?.image
            : state.authData.image,
          phone:action?.payload?.phone,
        },
      };
      case ActionType.DELETE_ACCOUNT:
        
        return {
          ...state,
          authData: {
            ...state.authData,
            delete_Account: action?.payload,
          },
        };
    default:
      return state;
  }
};

export default AuthReducer;
