const ROUTES = {
  LOGIN: "/login",
  HOME: "/",
  SIGN_UP: "/signup",
  FORGET_PASSOWRD: "/forgot-password",
  VERIFICATION: "/verification-code",
  RESET: "/reset-password",
  VIDEOS: "/videos",
  VIDEOS_LIST: "videos-list/:id",
  VIDEOS_DETAIL: "videos-list/:categoryId/videos-detail/:id",
  ARTICLE_DETAIL: "/article-detail/:id",
  PAYMENT: "/payment",
  TERMS_OF_SERVICE: "/terms-of-service",
  FAQ: "/faq",
  TECH_SUPPORT: "/tech-support",
  APPS: "/apps",
  CHANGE_PASSWORD: "/change-password",
  EDIT_PROFILE: "/edit-profile",
  MASTER_SERIES: "/semuto-master-series",
  GET_IN_TOUCH: "/get-in-touch",
  ABOUT: "/about",
  ACCOUNT_PAYMENT_HISTORY: "/account-payment-history",
  ACCOUNT_QUESTIONNARIE: "/account-questionnaire",
  ACCOUNT_APPS: "/account-apps",
  ACCOUNT_SUBSCRIPTION: "/account-subscription",
  CREATE_TICKET: "/create-ticket",
  PARTNERS: "/partners",
  ARTICLES: "/articles",
  GET_PLAN: "/get-plan",
  RESOURCE_HELP: "/resources-help",
  SET_YOUR_GOAL: "/set-your-goal",
  COMING_SOON: "/coming-soon",
  UNAUTHORIZE: "/*",
  TICKET_CHAT: "/ticket-chat/:id",
  HOME_PAGE_SUBSCRIPTION: "/:id",
  APP_DETAIL: "/app-details/:id",
  // CHECKOUT: "/checkout",
};

export const DONT_SHOW_AFTER_LOGIN_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.SIGN_UP,
  ROUTES.FORGET_PASSOWRD,
  ROUTES.RESET,
  ROUTES.VERIFICATION,
];

export const COMMON_ROUTES = [
  { path: ROUTES.HOME },
  { path: ROUTES.TERMS_OF_SERVICE },
  { path: ROUTES.ABOUT },
];

export const USER_ROUTES = [
  { path: ROUTES.VIDEOS },
  { path: ROUTES.VIDEOS_DETAIL },
  { path: ROUTES.VIDEOS_LIST },
  { path: ROUTES.ACCOUNT_APPS },
  { path: ROUTES.ACCOUNT_PAYMENT_HISTORY },
  { path: ROUTES.ACCOUNT_QUESTIONNARIE },
  { path: ROUTES.PAYMENT },
  { path: ROUTES.ACCOUNT_SUBSCRIPTION },
  { path: ROUTES.CHANGE_PASSWORD },
  { path: ROUTES.EDIT_PROFILE },
  { path: ROUTES.TECH_SUPPORT },
  { path: ROUTES.CREATE_TICKET },
  { path: ROUTES.MASTER_SERIES },
  { path: ROUTES.TICKET_CHAT },
];

export default ROUTES;
