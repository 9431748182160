import { IConfig } from "../interfaces/commonInterface";

const config:IConfig = {
  env: process.env.REACT_APP_NODE_ENV,
  baseUrl: process.env.REACT_APP_BASE_URL,
  devAdminUrl: process.env.REACT_APP_DEV_ADMIN_URL,
  imageUrl: process.env.REACT_APP_IMAGE_BASE_URL,
  stripeKey: process.env.REACT_APP_STRIPE_TEST_KEY,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
  LeptinMaster:process.env.REACT_APP_LEPTIN_MASTER,
  GutMaster:process.env.REACT_APP_GUT_MASTER,
  SolarMaster:process.env.REACT_APP_SOLAR_MASTER,
  SleepMaster:process.env.REACT_APP_SLEEP_MASTER,
};

export default config;
