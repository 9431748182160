import * as http from "../utils/http";
import endpoint from "../constant/endpoint";
import ApiResponse from "../resources/entity/IApiResponse";
import {
  ILoginData,
  ISignUp,
  IChangePassword,
  IForgetPassword,
  IVerifyOtp,
  IResetPassword,
} from "../interfaces/userAuthInterface";

/**
 * @description:- login api for  creating  user log in
 * @returns
 */
export const login = (data: ILoginData): Promise<ApiResponse> => {
  return http.post(endpoint.auth.LOGIN, data);
};

/**
 * @description:- signup api for  creating a new user
 * @returns
 */
export const signUp = (email: { email: string }): Promise<ApiResponse> => {
  return http.post(endpoint.auth.SIGNUP, email);
};

/**
 * @description:- changePassword api for  creating a new password
 * @returns
 */
export const changePassword = (data: IChangePassword): Promise<ApiResponse> => {
  return http.post(endpoint.auth.CHANGE_PASSWORD, data);
};

/**
 * @description:- forgotPassword api for  creating a new password
 * @returns
 */
export const forgotPassword = (data: IForgetPassword): Promise<ApiResponse> => {
  return http.post(endpoint.auth.FORGET_PASSWORD, data);
};

/**
 * @description:- deleteAccount api for delete user account
 * @returns
 */
export const deleteAccount = (data: IForgetPassword): Promise<ApiResponse> => {
  return http.post(endpoint.auth.DELETE_MY_ACCOUNT, data);
};

/**
 * @description:- verifyOtp api for  creating a verification authentication
 * @returns
 */
export const verifyOtp = (data: IVerifyOtp): Promise<ApiResponse> => {
  return http.post(endpoint.auth.VERIFY_OTP, data);
};

/**
 * @description:- verifySignupOtp api for  creating a verification authentication
 * @returns
 */
export const verifySignupOtp = (data: ISignUp): Promise<ApiResponse> => {
  return http.post(endpoint.auth.VERIFY_OTP_SIGNUP, data);
};

/**
 * @description:- verifyDeleteOtp api for  creating a verification authentication
 * @returns
 */
export const verifyDeleteOtp = (data: IVerifyOtp): Promise<ApiResponse> => {
  return http.post(endpoint.auth.VERIFY_OTP_DELETE, data);
};

/**
 * @description:- resetPassword api for  reseting password
 * @returns
 */
export const resetPassword = (data: IResetPassword): Promise<ApiResponse> =>
  http.post(endpoint.auth.RESET_PASSWORD, data);

/**
 * @description:- getusers profile data
 * @returns
 */
export const getProfileData = (): Promise<ApiResponse> => {
  return http.get(endpoint.auth.PROFILE_DATA);
};

/**
 * @description:- update terms and condition
 * @returns
 */
export const updateTermsAndCond = (data: { is_term_condition: number }): Promise<ApiResponse> =>
  http.post(endpoint.auth.TERMS_CONDITION, data);
