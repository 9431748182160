import * as http from "../utils/http";
import endpoint from "../constant/endpoint";
import ApiResponse from "../resources/entity/IApiResponse";

/**
 * @description:- getTermsOfUse api for  get terms of use and privacy policy by id
 * @returns
 */
export const getTermsOfUse = (body: { id: number }): Promise<ApiResponse> => {
  return http.post(endpoint.termsOfUse.GET_TERMS_OF_USE_DATA, body);
};
export default getTermsOfUse;
