import { AnyAction, Dispatch } from "redux";
import IUserInterface from "../../interfaces/userInterface";
import ActionType from "../../resources/enums";
// import storage from "../../utils/storage";

export const signinAction =
  (data: IUserInterface) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: ActionType.LOGIN,
      payload: data,
    });
  };
export const updateAuth =
  (data: unknown) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: ActionType.UPDATE_AUTH,
      payload: data,
    });
  };
  export const deleteAuth =
  (data: unknown) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: ActionType.DELETE_ACCOUNT,
      payload: data,
    });
  };
