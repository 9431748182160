import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { IAuthReducerState } from "../redux/reducers/AuthReducer";
import { RootState } from "../redux/reducers/Index";
import ProtectedRoutes from "./ProtectedRoutes";

import ROUTES from "../constant/routes";
import Loader from "../components/loader/Loader";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

/**
 * Lazily loaded components
 */
const About = lazy(() => import("../containers/about/About"));
const AccountApps = lazy(() => import("../containers/account/AccountApps"));

const AccountSubscription = lazy(
  () => import("../containers/account/AccountSubscription")
);
const Apps = lazy(() => import("../containers/Apps/Apps"));
const ArticleDetail = lazy(
  () => import("../containers/articles/ArticleDetail")
);
const Articles = lazy(() => import("../containers/articles/Articles"));
const ChangePassword = lazy(
  () => import("../containers/changePassword/ChangePassword")
);
const Chat = lazy(() => import("../containers/chat/Chat"));
const ComingSoon = lazy(() => import("../containers/comingSoon/ComingSoon"));
const EditProfile = lazy(() => import("../containers/editProfile/EditProfile"));
const FAQ = lazy(() => import("../containers/faq/FAQ"));
const GetInTouch = lazy(() => import("../containers/getInTouch/GetInTouch"));
const GetPlan = lazy(() => import("../containers/getPlan/GetPlan"));
const Home = lazy(() => import("../containers/home/Home"));
const Partners = lazy(() => import("../containers/partners/Partners"));
const Payment = lazy(() => import("../containers/payment/Payment"));
// const Checkout = lazy(() => import("../containers/payment/Payment"));
const ResourcesHelp = lazy(
  () => import("../containers/resourcesHelp/ResourcesHelp")
);
const SemutoMasterSeries = lazy(
  () => import("../containers/semutoMasterSeries/SemutoMasterSeries")
);
const SetYourGoal = lazy(() => import("../containers/setYourGoal/SetYourGoal"));
const TechSupport = lazy(() => import("../containers/techSupport/TechSupport"));
const TermsOfService = lazy(
  () => import("../containers/termsOfService/TermsOfService")
);
const CreateTicket = lazy(() => import("../containers/ticket/CreateTicket"));
const Unauthorize = lazy(
  () => import("../containers/unauthorize/Unauthorized")
);
const ForgotPassword = lazy(
  () => import("../containers/userAuth/ForgotPassword")
);
const Login = lazy(() => import("../containers/userAuth/Login"));
const ResetPassword = lazy(
  () => import("../containers/userAuth/ResetPassword")
);
const Signup = lazy(() => import("../containers/userAuth/Signup"));
const VerificationCode = lazy(
  () => import("../containers/userAuth/VerificationCode")
);
const Videos = lazy(() => import("../containers/videos/Videos"));
const VideosDetail = lazy(() => import("../containers/videos/VideosDetail"));
const VideosList = lazy(() => import("../containers/videos/VideosList"));
const AppDetail = lazy(() => import("../containers/appDetail/AppDetail"));

const PublicRoutes: React.FC = () => {
  const authReducer: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  return (
    <div className="theme-bg">
      <Router>
        <Header />
        <main className="main-container">
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route path={ROUTES.HOME_PAGE_SUBSCRIPTION} element={<Home />} />

              <Route
                path={ROUTES.LOGIN}
                element={
                  authReducer.isLoggedIn && authReducer.authData?.is_term_condition === 1 ?
                    (authReducer.isLoggedIn && authReducer.authData?.is_first_signin === 0
                      ? (<Navigate to={ROUTES.SET_YOUR_GOAL} />)
                      : (<Navigate to={ROUTES.HOME} />)
                    )
                    : (<Login />)
                }
              />
              <Route
                path={ROUTES.VERIFICATION}
                element={
                  authReducer.isLoggedIn && !authReducer.authData.delete_Account? (
                    <Navigate to={ROUTES.HOME} />
                  ) : (
                    <VerificationCode />
                  )
                }
              />
              <Route
                path={ROUTES.RESET}
                element={
                  authReducer.isLoggedIn ? (
                    <Navigate to={ROUTES.HOME} />
                  ) : (
                    <ResetPassword />
                  )
                }
              />
              <Route
                path={ROUTES.FORGET_PASSOWRD}
                element={
                  authReducer.isLoggedIn ? (
                    <Navigate to={ROUTES.HOME} />
                  ) : (
                    <ForgotPassword />
                  )
                }
              />
              <Route
                path={ROUTES.SIGN_UP}
                element={
                  authReducer.isLoggedIn ? (
                    <Navigate to={ROUTES.HOME} />
                  ) : (
                    <Signup />
                  )
                }
              />

              <Route
                path={ROUTES.VIDEOS_LIST}
                element={<ProtectedRoutes component={VideosList} />}
              />
              <Route
                path={ROUTES.VIDEOS}
                element={<ProtectedRoutes component={Videos} />}
              />
              <Route
                path={ROUTES.VIDEOS_DETAIL}
                element={<ProtectedRoutes component={VideosDetail} />}
              />

              <Route
                path={ROUTES.PAYMENT}
                element={<ProtectedRoutes component={Payment} />}
              />

              {/* <Route
              path={ROUTES.CHECKOUT}
              element={<ProtectedRoutes component={Checkout} />}
            /> */}
              {/* 
            <Route
              path={ROUTES.CHECKOUT}
              element={<ProtectedRoutes component={Payment} />}
            /> */}

              <Route path={ROUTES.ARTICLE_DETAIL} element={<ArticleDetail />} />

              <Route
                path={ROUTES.TERMS_OF_SERVICE}
                element={<TermsOfService />}
              />
              <Route path={ROUTES.FAQ} element={<FAQ />} />
              <Route path={ROUTES.APPS} element={<Apps />} />
              <Route
                path={ROUTES.CHANGE_PASSWORD}
                element={<ProtectedRoutes component={ChangePassword} />}
              />
              {/* <Route
              path={ROUTES.APP_DETAIL}
              element={<ProtectedRoutes component={APP_DETAIL} />}
            /> */}
              <Route path={ROUTES.APP_DETAIL} element={<AppDetail />} />

              <Route
                path={ROUTES.EDIT_PROFILE}
                element={<ProtectedRoutes component={EditProfile} />}
              />
              <Route
                path={ROUTES.MASTER_SERIES}
                element={<SemutoMasterSeries />}
              />
              <Route path={ROUTES.GET_IN_TOUCH} element={<GetInTouch />} />
              <Route path={ROUTES.ABOUT} element={<About />} />

              <Route
                path={ROUTES.ACCOUNT_APPS}
                element={<ProtectedRoutes component={AccountApps} />}
              />
              <Route
                path={ROUTES.CREATE_TICKET}
                element={<ProtectedRoutes component={CreateTicket} />}
              />

              <Route
                path={ROUTES.ACCOUNT_SUBSCRIPTION}
                element={<ProtectedRoutes component={AccountSubscription} />}
              />
              <Route path={ROUTES.CREATE_TICKET} element={<CreateTicket />} />
              <Route path={ROUTES.PARTNERS} element={<Partners />} />
              <Route path={ROUTES.TECH_SUPPORT} element={<TechSupport />} />

              <Route path={ROUTES.ARTICLES} element={<Articles />} />
              <Route path={ROUTES.GET_PLAN} element={<GetPlan />} />
              <Route path={ROUTES.RESOURCE_HELP} element={<ResourcesHelp />} />
              <Route path={ROUTES.TICKET_CHAT} element={<Chat />} />
              <Route path={ROUTES.SET_YOUR_GOAL} element={<SetYourGoal />} />
              <Route path={ROUTES.COMING_SOON} element={<ComingSoon />} />
              <Route path={ROUTES.UNAUTHORIZE} element={<Unauthorize />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </Router>
    </div>
  );
};

export default PublicRoutes;
