import * as http from "../utils/http";
import endpoint from "../constant/endpoint";
import ApiResponse from "../resources/entity/IApiResponse";

/**
 * @description:- getHeaderBannerOffer api
 * @returns
 */
export const getHeaderBannerOffer = (): Promise<ApiResponse> => {
  return http.get(endpoint.headerBanner.SUBSCRIPTION_OFFER_BANNER);
};

export default getHeaderBannerOffer;
