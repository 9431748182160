const ROUTES = {
  auth: {
    LOGIN: "user/login",
    SIGNUP: "user/register",
    CHANGE_PASSWORD: "/user/update/password",
    FORGET_PASSWORD: "/user/forgot-password",
    DELETE_MY_ACCOUNT: "/user/delete-my-account",
    VERIFY_OTP: "/user/otp-verification",
    RESET_PASSWORD: "/user/reset-password",
    VERIFY_OTP_SIGNUP: "/user/otp-verification-sign-up",
    VERIFY_OTP_DELETE: "/user/otp-verification-delete",
    PROFILE_DATA:"user/get",
    TERMS_CONDITION:"/updatetermcondition-sociallogin"
  },
  video: {
    CATEGORY: "video-category/get",
    EPISODES: "video/get",
  },
  article: {
    ARTICLE_CATEGORY: "article-category/get",
    ARTICLE_LIST: "article-list/get/",
    ARTICLE: "article/get/:id",
  },
  comment: { FETCH: "comment", POST: "post-comment" },
  support: {
    CREATE_TICKET: "/support/create",
    GET_TICKET: "/support/get",
    GET_TICKET_CHAT: "/support/get/:id",
    CREATE_TICKET_CHAT: "/support/reply/:ticketId",
  },
  Faq: {
    GET_FAQ: "/faq/category",
    GET_FAQ_BY_CATEGORY: "/faqs/:id ",
  },
  Questions: {
    GET_QUESTION: "/questions/get/:pageNo",
    GET_QUESTION_ANSWER: "/questions/getquestionnaire/:pageNo",
    CREATE_QUESTION: "/questions/question",
  },
  Profile: {
    EDIT_PROFILE: "/edit-profile",
  },
  MasterSerries: {
    APP_CATEGORY: "/app-category",
    APP_DATA: "/app-data",
    USER_APP_DATA: "/user-selected-apps-score",
    RECOMMENDED_APP_DATA: "/user-answers",
    SAVE_APP_DATA: "/selected-app",
    SELECTED_APP_DATA: "/get-selected-app",
  },
  testimonial: {
    TESTIMONIAL: "/testimonials",
  },
  user: {
    GET_VIDEO_PROGRESS_BAR: "/user/video-detail",
    POST_SOCIAL_LOGIN: "user/login-social-media",
  },
  partners: {
    GET_PARTNERS_DATA: "/partners",
  },
  termsOfUse: {
    GET_TERMS_OF_USE_DATA: "/page/get",
  },
  stripe: {
    GET_SUBSCRIPTION_PLANS: "/get-subscription-plans",
    SUBSCRIPTION_PLAN_PAYMENT: "/subscription-plan-payment",
    USER_SUBSCRIPTION: "/user-subscription",
    USER_PAYMENT_HISTORY: "/user-payment-history",
    CANCEL_USER_CURRENT_SUBSCRIPTION: "/cancel-subscription",
    GET_COUPON: "/get-coupons",
    GET_COUPON_BY_COUPON_ID: "/get-coupons/:stripe_coupon_id",
    VALIDATE_COUPON: "/validate-coupon",
    SUBSCRIPTION_OFFER: "/subscription-offer",
  },
  contactUs: {
    CONTACT_US: "/contact/create",
  },
  appDetail: {
    GET_APP_DETAIL_BY_ID: "/app-details/:id",
  },
  headerBanner: {
    SUBSCRIPTION_OFFER_BANNER: "/subscription-offer-banner",
  },
};

export default ROUTES;
