import PublicRoutes from "./routes/PublicRoutes";

import "./components/i18n/I18n";

import "bootstrap/dist/css/bootstrap.css";
import "./theme/App.scss";
import "./theme/all.min.css";

// eslint-disable-next-line
function App() {
  return (
    <div className="App">
      <PublicRoutes />
    </div>
  );
}

export default App;
